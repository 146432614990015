/*
  마이렛미업 설정 페이지
*/
import React, { useCallback, useEffect, useState } from "react"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import LeftSideBar from "../../components/my/LeftSideBar"
import RightSideBar from "../../components/my/RightSideBar"
import temp_profile from "../../images/editicon.png"
import PENCIL from "../../images/PENCIL.svg"
import X_gray from "../../images/X_gray.svg"
import plus_white from "../../images/plus_white.svg"
import checkbox_true from "../../images/checkbox_true.svg"
import checkbox_false from "../../images/checkbox_false.svg"
import camera_icon from "../../images/camera_icon.svg"
import mic_icon from "../../images/mic_icon.svg"
import speeker_icon from "../../images/speeker_icon.svg"
import { Desktop, Mobile } from "../../components/layouts/mediaQuery"
import { setEditMyProfilePopup, setAddStudyLocationPopup } from "../../store/modules/popup"
import { useDispatch } from "react-redux"
import PrivateRoute from "../../components/privateRoute"
import { dataCheckFn, formatInputPhone, localStorgeGet, popupFn } from "../../utils/util"
import { debounce } from "lodash"

import UserContainer from "../../containers/UserContainer"
import SignContainer from "../../containers/SignContainer"
import MyContainer from "../../containers/MyContainer"
import { useSelector } from "react-redux"

import ModalPopup from "../../popups/ModalPopup"

import { rnMessageListener } from "../../utils/util"
import { navigate } from "gatsby-link"

import { setTermsPopup } from "../../store/modules/popup"

import TermsPopup from "../../popups/TermsPopup"
import SignApis from "../../apis/SignApis"
const Setting = props => {
  const {
    putUserSettingGear,
    putUserSettingsContent,
    getMyProfileAction,
    putUserProfliePhone,
    getMySetting,
    userSetting,
    putUserStudyLocation,
    delUserStudyLocation,
    putUserSettingRecommendation,
    putUserSettingAlarm,
    putUserSettingTerm,
    getTerm,
  } = props
  const dispatch = useDispatch()
  const [studyLoc, setStudyLoc] = useState([])
  const [openContents, setOpenContents] = useState(false)
  const [conProfile, setConProfile] = useState(false)
  const [conSchedule, setConSschedule] = useState(false)
  const [conTodo, setConTodo] = useState(false)
  const [referFriends, setReferFriends] = useState(false)
  const [onCam, setOnCam] = useState(false)
  const [onMic, setOnMic] = useState(false)
  const [onSpeeker, setOnSpeeker] = useState(false)
  const [onNoti, setOnNoti] = useState(false)
  const [onAdNoti, setOnAdNoti] = useState(false)
  const [onAdNotiEmail, setOnAdNotiEmail] = useState(false)
  const [onAdNotiSNS, setOnAdNotiSNS] = useState(false)
  const [onTermUse, setOntermUse] = useState(false)
  const [onTermPrivacy, setOnTermPrivacy] = useState(false)
  const [camList, setCamList] = useState([])
  const [audioInputList, setAudioInputList] = useState([])
  const [audioOutputList, setAudioOutputList] = useState([])
  const [openChangeLoc, setOpenChangeLoc] = useState(false)
  const [selLocSeq, setSelLocSeq] = useState("")
  const [studyLocList, setStudyLocList] = useState([])

  const [step, setStep] = useState(0)

  const editPofliePopup = useSelector(state => state.popup.editMyProfile)
  const addStudyLocation = useSelector(state => state.popup.addStudyLocation)

  const getData = async () => {
    await getTerm()
    await getMySetting()
    let type = await SignApis.postAuthentication()
    await getMyProfileAction({ managerUserSeq: localStorgeGet("loginUserSeq"), managerUserType: type?.data?.loginUserType || "" })
  }

  //초기 장치 설정
  const gearFn = async type => {
    let temp = {
      isUseCamera: (type == 0 ? !onCam : onCam) ? "Y" : "N",
      isUseMicrophone: (type == 1 ? !onMic : onMic) ? "Y" : "N",
      isUseSpeaker: (type == 2 ? !onSpeeker : onSpeeker) ? "Y" : "N",
    }
    await putUserSettingGear(temp)
    switch (type) {
      case 0:
        setOnCam(!onCam)
        break
      case 1:
        setOnMic(!onMic)
        break
      case 2:
        setOnSpeeker(!onSpeeker)
        break
      default:
        break
    }
  }
  //컨텐츠 공개
  const conFn = async type => {
    let temp = {
      isOpenProfile: (type == 0 ? !conProfile : conProfile) ? "Y" : "N",
      isOpenStudy: (type == 1 ? !conSchedule : conSchedule) ? "Y" : "N",
      isOpenTodo: (type == 2 ? !conTodo : conTodo) ? "Y" : "N",
    }

    await putUserSettingsContent(temp)
    switch (type) {
      case 0:
        setConProfile(!conProfile)
        break
      case 1:
        setConSschedule(!conSchedule)
        break
      case 2:
        setConTodo(!conTodo)
        break
      default:
        break
    }
  }
  //컨텐츠 공개
  const conAllFn = async () => {
    let temp = {
      isOpenProfile: !openContents ? "Y" : "N",
      isOpenStudy: !openContents ? "Y" : "N",
      isOpenTodo: !openContents ? "Y" : "N",
    }

    await putUserSettingsContent(temp)

    setConProfile(!openContents)

    setConSschedule(!openContents)

    setConTodo(!openContents)
    setOpenContents(!openContents)
  }
  //친구 추천
  const referFriendsFn = async () => {
    let temp = {
      isRecommendation: !referFriends ? "Y" : "N",
    }
    await putUserSettingRecommendation(temp)
    setReferFriends(!referFriends)
  }
  //내 알림
  const notiFn = async type => {
    let onAdNotiEmailtemp = onAdNotiEmail
    let onAdNotiSNStemp = onAdNotiSNS
    let onNotiTmep = onNoti
    if (type == 3) {
      if (!onAdNoti) {
        setOnAdNotiEmail(true)
        setOnAdNotiSNS(true)
        onAdNotiEmailtemp = true
        onAdNotiSNStemp = true
      } else {
        setOnAdNotiEmail(false)
        setOnAdNotiSNS(false)
        onAdNotiEmailtemp = false
        onAdNotiSNStemp = false
      }
      setOnAdNoti(!onAdNoti)
    } else if (type == 1) {
      setOnAdNotiEmail(!onAdNotiEmail)
      onAdNotiEmailtemp = !onAdNotiEmailtemp
    } else if (type == 2) {
      setOnAdNotiSNS(!onAdNotiSNS)
      onAdNotiSNStemp = !onAdNotiSNStemp
    } else if (type == 0) {
      setOnNoti(!onNoti)
      onNotiTmep = !onNotiTmep
    }
    let temp = {
      isAlarm: onNotiTmep ? "Y" : "N",
      marketingType: onAdNotiEmailtemp && onAdNotiSNStemp ? "ALL" : onAdNotiEmailtemp ? "EMAIL" : onAdNotiSNStemp ? "SNS/SMS" : "NONE",
    }
    await putUserSettingAlarm(temp)
  }
  //약관
  const termFn = async type => {
    let temp = {
      termUse: (type == 0 ? !onTermUse : onTermUse) ? "Y" : "N",
      termPrivacy: (type == 1 ? !onTermPrivacy : onTermPrivacy) ? "Y" : "N",
    }
    await putUserSettingTerm(temp)
    switch (type) {
      case 0:
        setOntermUse(!onTermUse)
        break
      case 1:
        setOnTermPrivacy(!onTermPrivacy)
        break

      default:
        break
    }
  }
  //컨텐츠 공개
  const openContentsFn = () => {
    if (!openContents) {
      setConProfile(true)
      setConSschedule(true)
      setConTodo(true)
      setOpenContents(true)
    } else {
      setConProfile(false)
      setConSschedule(false)
      setConTodo(false)
      setOpenContents(false)
    }
    conFn(4)
  }
  //광고 알림
  const onAdNotiFn = () => {
    if (!onAdNoti) {
      setOnAdNotiEmail(true)
      setOnAdNotiSNS(true)
      setOnAdNoti(true)
    } else {
      setOnAdNotiEmail(false)
      setOnAdNotiSNS(false)
      setOnAdNoti(false)
    }
  }

  //공부장소 변경
  const changeLocFn = debounce(async () => {
    let result = await putUserStudyLocation({ studyLocationSeq: selLocSeq })
    if (result) {
      popupFn("변경되었습니다.")
      setSelLocSeq("")
      setOpenChangeLoc(false)
      await getData()
    }
  }, 500)
  //공부장소 삭제
  const delLocFn = debounce(async studyLocationSeq => {
    let result = await delUserStudyLocation({ studyLocationSeq: studyLocationSeq })
    if (result) {
      popupFn("삭제되었습니다.")
      await getData()
    }
  }, 500)

  //장치 권한 && 목록 불러오기
  const deviceFn = async () => {
    if (navigator.mediaDevices.getUserMedia) {
      let constraints = { audio: true }

      //권한 성공
      let onSuccess = async stream => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
          return false
        }

        let audioInput = []
        let audioOutput = []
        let cam = []
        //목록 가져오기
        let devices = await navigator.mediaDevices.enumerateDevices()
        //뿌리기
        devices.forEach(device => {
          switch (device.kind) {
            case "audioinput":
              if (device.deviceId == "default" || device.deviceId == "communications") break
              audioInput = [...audioInput, device]
              break
            case "videoinput":
              if (device.deviceId == "default" || device.deviceId == "communications") break
              cam = [...cam, device]
              break
            case "audiooutput":
              if (device.deviceId == "default" || device.deviceId == "communications") break
              audioOutput = [...audioOutput, device]
              break
            default:
              console.log("Skipped Device: " + device.kind, device && device.label)
              break
          }
        })
        setCamList(cam)
        setAudioInputList(audioInput)
        setAudioOutputList(audioOutput)
      }
      //권한 에러
      let onError = async err => {
        // if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        //   return false
        // }

        // let audioInput = []
        // let audioOutput = []
        // let cam = []
        // //목록 가져오기
        // let devices = await navigator.mediaDevices.enumerateDevices()
        // //뿌리기
        // devices.forEach(device => {
        //   switch (device.kind) {
        //     case "audioinput":
        //       if (device.deviceId == "default" || device.deviceId == "communications") break
        //       audioInput = [...audioInput, device]
        //       break
        //     case "videoinput":
        //       if (device.deviceId == "default" || device.deviceId == "communications") break
        //       cam = [...cam, device]
        //       break
        //     case "audiooutput":
        //       if (device.deviceId == "default" || device.deviceId == "communications") break
        //       audioOutput = [...audioOutput, device]
        //       break
        //     default:
        //       console.log("Skipped Device: " + device.kind, device && device.label)
        //       break
        //   }
        // })
        // setCamList(cam)
        // setAudioInputList(audioInput)
        // setAudioOutputList(audioOutput)
        console.log("The following error occured: " + err)
      }

      //권한 요청
      navigator.mediaDevices.getUserMedia(constraints).then(onSuccess, onError)
    } else {
      console.log("getUserMedia not supported on your browser!")
    }
  }

  //장치 준비 후 목록 불러오기
  useEffect(() => {
    deviceFn()
  }, [])

  // //컨텐츠 공개
  // useEffect(() => {
  //   conFn()
  //   if (conProfile && conSchedule && conTodo) {
  //     setOpenContents(true)
  //   }
  // }, [conProfile, conSchedule, conTodo])

  // //내 알림
  // useEffect(() => {
  //   notiFn()
  // }, [onNoti, onAdNotiEmail, onAdNotiSNS])

  //내 공부장소 가장위로
  useEffect(() => {
    if (dataCheckFn(userSetting)) {
      if (userSetting.studyLocations.length != 0) {
        let arr = []
        for (let i of userSetting.studyLocations) {
          if (i.isUse == "Y") {
            arr = [...arr, i]
          }
        }
        for (let i of userSetting.studyLocations) {
          if (i.isUse != "Y") {
            arr = [...arr, i]
          }
        }
        setStudyLocList(arr)
      }
    }
  }, [userSetting])

  //프로필 팝업 close시 getData
  useEffect(() => {
    if (!editPofliePopup && !addStudyLocation) getData()
  }, [editPofliePopup, addStudyLocation])
  useEffect(() => {
    if (dataCheckFn(userSetting)) {
      setConProfile(userSetting.content.isOpenProfile == "Y" ? true : false)
      setConSschedule(userSetting.content.isOpenStudy == "Y" ? true : false)
      setConTodo(userSetting.content.isOpenTodo == "Y" ? true : false)
      setReferFriends(userSetting.recommendation.isUse == "Y" ? true : false)
      setOnCam(userSetting.gear.isUseCamera == "Y" ? true : false)
      setOnMic(userSetting.gear.isUseMicrophone == "Y" ? true : false)
      setOnSpeeker(userSetting.gear.isUseSpeaker == "Y" ? true : false)
      setOnNoti(userSetting.alarm.isUse == "Y" ? true : false)
      setOnAdNotiEmail(userSetting.marketingAlarm.Type == "ALL" || userSetting.marketingAlarm.Type == "EMAIL" ? true : false)
      setOnAdNotiSNS(userSetting.marketingAlarm.Type == "ALL" || userSetting.marketingAlarm.Type == "SNS" ? true : false)
      setOntermUse(userSetting.term.use == "Y" ? true : false)
      setOnTermPrivacy(userSetting.term.privacy == "Y" ? true : false)
    }
  }, [userSetting])
  useEffect(() => {
    return () => {
      dispatch(setEditMyProfilePopup(false))
      dispatch(setAddStudyLocationPopup(false))
    }
  }, [])
  const messageDataFn = useCallback(e => {
    let data = JSON.parse(e.data)
    if (data.type == "BACK") navigate(-1)
  }, [])

  useEffect(() => {
    if (window.ReactNativeWebView) {
      document.addEventListener("message", messageDataFn)
    }
    return () => {
      document.removeEventListener("message", messageDataFn)
    }
  }, [])
  return (
    <PrivateRoute>
      <Layout title="설정">
        <TermsPopup step={step} setStep={setStep} />
        <ModalPopup
          btnText={"확인"}
          open={openChangeLoc}
          onClose={() => setOpenChangeLoc(false)}
          contents={"사용중인 공부장소가 변경됩니다."}
          onClickBtn={() => changeLocFn()}
        />
        <SEO title="마이렛미업" />
        <div className="d-flex justify-content-center ">
          <LeftSideBar />
          <div className="container my-container">
            <div className="my-setting-div">
              <Desktop>
                <div className="my-setting-title">설정</div>
              </Desktop>
              <div className="my-setting-contents-container">
                <div className="my-setting-border-box my-setting-proflie-box">
                  <img
                    src={
                      dataCheckFn(userSetting)
                        ? userSetting.basicInfomation.profileImage != null && userSetting.basicInfomation.profileImage != ""
                          ? userSetting.basicInfomation.profileImage
                          : temp_profile
                        : temp_profile
                    }
                    className="profile-img"
                  ></img>
                  <div className="my-setting-proflie-right-box">
                    <Mobile>
                      <p className="name-p">내 정보</p>
                    </Mobile>
                    <p className="name-p">
                      {(dataCheckFn(userSetting) && userSetting.basicInfomation.name) || "NAME"}&nbsp;&nbsp;
                      {(dataCheckFn(userSetting) && userSetting.basicInfomation.nickname) || "USER_NAME"}
                    </p>
                    <p className="phone-p">
                      {formatInputPhone((dataCheckFn(userSetting) && userSetting.basicInfomation.phone) || "01012345678")}
                      <br />
                      {(dataCheckFn(userSetting) && userSetting.basicInfomation.email) || "abc@email.com"}
                    </p>
                    <div className="my-setting-btn-div">
                      <div onClick={() => dispatch(setEditMyProfilePopup(true))} className="my-setting-btn">
                        <p>편집하기</p>
                        <img src={PENCIL}></img>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-setting-border-box my-setting-studyroom-box">
                  <p className="my-setting-studyroom-title">공부장소</p>
                  {studyLocList.map((i, idx) => (
                    <div
                      onClick={() => {
                        if (i.isUse == "Y") return false
                        setOpenChangeLoc(true)
                        setSelLocSeq(i.studyLocationSeq)
                      }}
                      className="my-setting-studyroom-list"
                      key={idx}
                    >
                      <div className="my-setting-studyroom-frist-line">
                        <div className="my-setting-studyroom-name">
                          <p className="room-name-p">{i.nickname}</p>
                          {i.isUse == "Y" && <div className="main-room">사용중</div>}
                        </div>
                        <img
                          onClick={e => {
                            e.stopPropagation()
                            delLocFn(i.studyLocationSeq)
                          }}
                          src={X_gray}
                        ></img>
                      </div>
                      <p className="my-setting-studyroom-se-line">{i.addressOld}</p>
                    </div>
                  ))}
                  <div className="my-setting-btn-div">
                    <div onClick={() => dispatch(setAddStudyLocationPopup(true))} className="my-setting-btn">
                      <p>추가하기</p>
                      <img src={plus_white}></img>
                    </div>
                  </div>
                </div>
                <div className="my-setting-border-box my-setting-contents-open-box">
                  <div className="my-setting-contents-open-title-div">
                    <p className="my-setting-contents-open-title">컨텐츠 전체 공개</p>
                    <div className="form-check form-switch form-switch-md">
                      <input checked={conProfile && conSchedule && conTodo} onChange={conAllFn} className="form-check-input" type="checkbox" />
                    </div>
                  </div>
                  <div onClick={() => conFn(0)} className="my-setting-contents-open-div">
                    <img src={conProfile ? checkbox_true : checkbox_false}></img>
                    <p className="my-setting-contents-open-div-p">내 프로필</p>
                  </div>
                  <div onClick={() => conFn(1)} className="my-setting-contents-open-div">
                    <img src={conSchedule ? checkbox_true : checkbox_false}></img>
                    <p className="my-setting-contents-open-div-p">스터디 스케줄</p>
                  </div>
                  <div onClick={() => conFn(2)} className="my-setting-contents-open-div">
                    <img src={conTodo ? checkbox_true : checkbox_false}></img>
                    <p className="my-setting-contents-open-div-p">스터디 TO-DO</p>
                  </div>
                </div>
                <div className="my-setting-border-box my-setting-contents-open-box">
                  <div className="my-setting-contents-open-title-div">
                    <p className="my-setting-contents-open-title">친구 추천</p>
                    <div className="form-check form-switch form-switch-md">
                      <input checked={referFriends} onChange={() => referFriendsFn()} className="form-check-input" type="checkbox" />
                    </div>
                  </div>
                  <div className="my-setting-contents-open-div">
                    <p className="my-setting-contents-open-div-p my-setting-contents-open-div-zero-margin">
                      렛미업 회원 유형 및 스터디 키워드에 따른
                      <br /> 친구 추천을 허용합니다.
                    </p>
                  </div>
                </div>
                <div className="my-setting-border-box my-setting-device-box">
                  <div className="my-setting-device-box-title-div">
                    <p className="my-setting-device-box-title">내 컴퓨터 초기 장치</p>
                  </div>
                  <div className="my-setting-subtitle-div">
                    <p className="my-setting-subtitle-div-p">
                      스터디에 참가하는 사용자는 스터디 개설자가 설정한
                      <br /> 초기 장치 설정에 따라 장치 제어가 적용됩니다.
                    </p>
                  </div>
                  <div className="my-setting-device-list">
                    <div className="my-setting-device-frist-line">
                      <div className="my-setting-device-name">
                        <img src={camera_icon}></img>
                        <div className="name-p">카메라</div>
                      </div>
                      <div className="form-check form-switch form-switch-md">
                        <input
                          checked={onCam}
                          onChange={() => {
                            gearFn(0)
                          }}
                          className="form-check-input"
                          type="checkbox"
                        />
                      </div>
                    </div>
                    <div className="my-setting-device-dropdown">
                      <select
                        className="form-select select-css"
                        onChange={e => {
                          console.log(e.target.value)
                        }}
                      >
                        {(camList.length > 0 ? camList : [{ label: "기기없음", deviceId: "" }]).map((i, idx) => (
                          <option key={idx} className="option-css" value={i}>
                            {i.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="my-setting-device-info"> {camList.length > 0 && `이 장치가 올바르게 작동하고 있습니다.`}</p>
                  </div>
                  <div className="my-setting-device-list">
                    <div className="my-setting-device-frist-line">
                      <div className="my-setting-device-name">
                        <img src={mic_icon}></img>
                        <div className="name-p">마이크</div>
                      </div>
                      <div className="form-check form-switch form-switch-md">
                        <input checked={onMic} onChange={() => gearFn(1)} className="form-check-input" type="checkbox" />
                      </div>
                    </div>
                    <div className="my-setting-device-dropdown">
                      <select
                        className="form-select select-css"
                        onChange={e => {
                          console.log(e.target.value)
                        }}
                      >
                        {(audioInputList.length > 0 ? audioInputList : [{ label: "기기없음", deviceId: "" }]).map((i, idx) => (
                          <option key={idx} className="option-css" value={i}>
                            {i.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="my-setting-device-info"> {camList.length > 0 && `이 장치가 올바르게 작동하고 있습니다.`}</p>
                  </div>
                  <div className="my-setting-device-list">
                    <div className="my-setting-device-frist-line">
                      <div className="my-setting-device-name">
                        <img src={speeker_icon}></img>
                        <div className="name-p">스피커</div>
                      </div>
                      <div className="form-check form-switch form-switch-md">
                        <input checked={onSpeeker} onChange={() => gearFn(2)} className="form-check-input" type="checkbox" />
                      </div>
                    </div>
                    <div className="my-setting-device-dropdown">
                      <select
                        className="form-select select-css"
                        onChange={e => {
                          console.log(e.target.value)
                        }}
                      >
                        {(audioOutputList.length > 0 ? audioOutputList : [{ label: "기기없음", deviceId: "" }]).map((i, idx) => (
                          <option key={idx} className="option-css" value={i}>
                            {i.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="my-setting-device-info"> {camList.length > 0 && `이 장치가 올바르게 작동하고 있습니다.`}</p>
                  </div>
                </div>
                <div className="my-setting-border-box my-setting-contents-open-box">
                  <div className="my-setting-contents-open-title-div my-setting-contents-open-div-bottom-margin">
                    <p className="my-setting-contents-open-title">알림 받기</p>
                    <div className="form-check form-switch form-switch-md">
                      <input checked={onNoti} onChange={() => notiFn(0)} className="form-check-input" type="checkbox" />
                    </div>
                  </div>
                  <div className="my-setting-contents-open-div">
                    <p className="my-setting-contents-open-div-p my-setting-contents-open-div-zero-margin">
                      알림으로 본인 인증, 구매, 스터디 진행 <br />
                      관련 등의 정보를 받습니다.
                    </p>
                  </div>
                  <div className="my-setting-contents-open-title-div my-setting-contents-open-div-bottom-margin">
                    <p className="my-setting-contents-open-title">광고성 알림 받기</p>
                    <div className="form-check form-switch form-switch-md">
                      <input checked={onAdNotiEmail || onAdNotiSNS} onChange={() => notiFn(3)} className="form-check-input" type="checkbox" />
                    </div>
                  </div>
                  <div className="my-setting-contents-open-div">
                    <p className="my-setting-contents-open-div-p my-setting-contents-open-div-zero-margin">
                      포인트, 프로모션, 이벤트 등 렛미업의 다양한 혜택
                      <br /> 정보를 받습니다.
                    </p>
                  </div>
                  <div onClick={() => notiFn(3)} className="my-setting-contents-open-div">
                    <img src={onAdNotiEmail && onAdNotiSNS ? checkbox_true : checkbox_false}></img>
                    <p className="my-setting-contents-open-div-p">전체</p>
                  </div>
                  <div onClick={() => notiFn(1)} className="my-setting-contents-open-div">
                    <img src={onAdNotiEmail ? checkbox_true : checkbox_false}></img>
                    <p className="my-setting-contents-open-div-p">이메일</p>
                  </div>
                  <div onClick={() => notiFn(2)} className="my-setting-contents-open-div">
                    <img src={onAdNotiSNS ? checkbox_true : checkbox_false}></img>
                    <p className="my-setting-contents-open-div-p">SNS 메세지</p>
                  </div>
                  <p className="bottom-info-p">
                    알림 설정은 스마트폰 단말기에서만 유효하며, <br />
                    아이폰은 단말기의 설정 > 알림 > LET ME UP 에서 설정해주세요.
                  </p>
                </div>
                <div className="my-setting-border-box my-setting-trem-box">
                  <div className="my-setting-trem-box-title-div">
                    <p className="my-setting-trem-box-title">이용약관</p>
                  </div>
                  <div className="my-setting-trem-div">
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(setTermsPopup({ open: true, type: 0 }))
                      }}
                      className="my-setting-trem-div-p"
                    >
                      서비스 이용 약관
                    </p>
                    <div className="form-check form-switch form-switch-md">
                      <input checked={onTermUse} onChange={() => termFn(0)} className="form-check-input" type="checkbox" />
                    </div>
                  </div>
                  <div className="my-setting-trem-div">
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(setTermsPopup({ open: true, type: 1 }))
                      }}
                      className="my-setting-trem-div-p"
                    >
                      개인정보 활용동의
                    </p>
                    <div className="form-check form-switch form-switch-md">
                      <input checked={onTermPrivacy} onChange={() => termFn(1)} className="form-check-input" type="checkbox" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RightSideBar />
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default SignContainer(MyContainer(UserContainer(Setting)))
